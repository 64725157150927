import React, { useReducer, createContext, Dispatch } from 'react'
import { IAdmins } from './admins.interface';

type Action = { type: "SET_ADMINS"; admins: IAdmins[] };

const initialState: { admins: IAdmins[] } = {
  admins: [],
};

const reducer = (state: typeof initialState = initialState, action: Action) => {
  switch (action.type) {
    case "SET_ADMINS":
      state.admins = action.admins;
      const newState = {...state};
      return newState;
    default:
      return state;
  }
};

const AdminContext = createContext<{state: typeof initialState; dispatch: Dispatch<Action>}>({
  state: initialState,
  dispatch: () => null
});

const AdminsProvider: React.FC = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <AdminContext.Provider value={value}>{props.children}</AdminContext.Provider>
  );
}

export {
  AdminsProvider,
  AdminContext,
  initialState,
}
