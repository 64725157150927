// navigation component top bar with links to other components

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../auth/auth.context";

const Nav = () => {
  const { dispatch } = useContext(AuthContext);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div className="nav">
        <Link to="/status">Status</Link>
        &nbsp;-&nbsp;
        <Link to="/admins">Admins</Link>
        &nbsp;-&nbsp;
        <Link to="/users">Users</Link>
        &nbsp;-&nbsp;
        <Link to="/accounts">Accounts</Link>
        &nbsp;-&nbsp;
        <Link to="/networks">Networks</Link>
        &nbsp;-&nbsp;
        <Link to="/settings">Settings</Link>
      </div>
      <a
        href="/"
        onClick={() => {
          dispatch({
            type: "REMOVE_JWT",
          });
        }}
      >
        Logout
      </a>
    </div>
  );
};

export default Nav;
