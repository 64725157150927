import React, {useContext, useEffect} from 'react';
import {AdminContext} from './admins.context';
import {AuthContext} from '../auth/auth.context';
import {getAdmins} from './admins.api';
import Nav from '../nav/nav.component';

const Admins = ({data}: any) => {

    const {state, dispatch} = useContext(AdminContext);
    const authContext = useContext(AuthContext);
    
    useEffect(() => {
        async function getAdminsFromApi() {
            const admins = await getAdmins(authContext.state.jwt);
            dispatch({
                type: 'SET_ADMINS',
                admins,
            });
        }

        async function startup() {
            await getAdminsFromApi();
        }
        
        startup();
// eslint-disable-next-line
    }, []);

    return (
        <div>
            <Nav />
    
            <div className="container admins-list">
                <table>
                    <tr>
                        <th>id</th>
                        <th>email</th>
                        <th>name</th>
                        <th>status</th>
                    </tr>
                    {state.admins.map((admin, index)=>{
                        return (
                            <tr key={`admin-${index}`}>
                                <td>{admin.adminUuid}</td>
                                <td>{admin.adminEmail}</td>
                                <td>{admin.adminFirstname} {admin.adminLastname}</td>
                                <td>{admin.adminStatus ? 'active' : 'inactive'}</td>
                            </tr>
                        )
                    })}
                </table>
            </div>
        </div>
    )
}
export default Admins;