import React, { useContext, useEffect } from "react";
import { SettingsContext } from "./settings.context";
import { AuthContext } from "../auth/auth.context";
import { getSettings, updateSettings } from "./settings.api";
import { Formik, Form, Field } from "formik";
import Nav from "../nav/nav.component";

const Settings = ({ data }: any) => {
  const { state, dispatch } = useContext(SettingsContext);
  const authContext = useContext(AuthContext);
  let buttonText = "Submit";

  useEffect(() => {
    async function getSettingsFromApi() {
      const settings = await getSettings(authContext.state.jwt);
      dispatch({
        type: "SET_SETTINGS",
        settings,
      });
    }

    async function startup() {
      await getSettingsFromApi();
    }

    startup();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (values: any) => {
    const opportunityBlacklistDomains = values.opportunityBlacklistDomains
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => !!line)
      .join(",");

    const responseStatus = await updateSettings(authContext.state.jwt, {
      opportunityBlacklistDomains,
    });

    if (responseStatus) {
      buttonText = "Saved";
    } else {
      buttonText = "Error";
    }
  };

  if (!state.settings) return null;

  return (
    <div>
      <Nav />
      <div className="container settings-list">
        <h1>Settings</h1>
        <Formik
          initialValues={{
            opportunityBlacklistDomains:
              state.settings.opportunityBlacklistDomains.split(",").join("\n"),
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form className="form">
              <label htmlFor="network-name">
                Opportunity subdomain blacklist:
              </label>
              <Field
                type="text"
                as="textarea"
                id="opportunity-blacklist-domains"
                name="opportunityBlacklistDomains"
                placeholder="developer.apple.com&#10;devforums.apple.com"
                style={{
                  minHeight: 300,
                }}
              />
              <button type="submit">{buttonText}</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Settings;
