import React, {useContext, useEffect} from 'react';
import {NetworkContext} from './networks.context';
import {AuthContext} from '../auth/auth.context';
import {getNetworks} from './networks.api';
import Nav from '../nav/nav.component';
import { Link } from 'react-router-dom';

const Networks = ({data}: any) => {
    const {state, dispatch} = useContext(NetworkContext);
    const authContext = useContext(AuthContext);
    
    useEffect(() => {
        async function getNetworksFromApi() {
            const networks = await getNetworks(authContext.state.jwt);
            dispatch({
                type: 'SET_NETWORKS',
                networks,
            });
        }

        async function startup() {
            await getNetworksFromApi();
        }

        startup();
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Nav />
            <Link to="/networks/create">Create custom network</Link>
            <div className="container networks-list">
                <table>
                    <thead>
                    <tr>
                        <th>network uuid</th>
                        <th>name</th>
                        <th>is custom</th>
                        <th>action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {state.networks.sort((a,b) => a.networkName < b.networkName ? - 1 : 1).map((network, index)=>{
                        return (
                            <tr key={`network-${index}`}>
                                <td>{network.networkUuid}</td>
                                <td>{network.networkName}</td>
                                <td>{network.networkIsCustom}</td>
                                <td>
                                    <Link to={`/networks/`+network.networkUuid}>View</Link>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Networks;