import React, {useContext} from 'react';
import {AuthContext} from '../auth/auth.context';
import {createCustomNetwork} from './networks.api';
import Nav from '../nav/nav.component';
import { Formik, Form, Field } from "formik";
import { useHistory } from 'react-router-dom';

const Network = ({data}: any) => {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    let buttonText = 'Submit';

    const handleSubmit = async (values: any) => {
        // handle form submission here
        const responseStatus = await createCustomNetwork(authContext.state.jwt, {
            networkName: values.networkName || '',
            networkUrl: values.networkUrl || '',
            networkIdentifier: values.networkIdentifier || '',
        });
        if (responseStatus) {
            buttonText = 'Saved';
            // change router to /networks
            history.push('/networks');
        } else {
            buttonText = 'Error';
        }
      };

    return (
        <div>
            <Nav />
            <div className="container">

                <Formik
                    initialValues={{
                        networkName: '',
                        networkUrl: '',
                        networkIdentifier: '',
                    }}
                    onSubmit={handleSubmit}
                    >

                    {({ values, handleChange, setFieldValue }) => (
                        <Form className="form">
                        <label htmlFor="network-name">Name:</label>
                        <Field
                            type="text"
                            id="network-name"
                            name="networkName"
                        />

                        <label htmlFor="network-url">Url:</label>
                        <Field
                            type="text"
                            id="network-url"
                            name="networkUrl"
                        />

                        <label htmlFor="network-identifier">Identifier:</label>
                        <small>(cannot be changed - will be prepended with "custom-")</small>
                        <Field
                            type="text"
                            id="network-identifier"
                            name="networkIdentifier"
                        />

                        <button type="submit">{buttonText}</button>
                        </Form>
                    )}
                    </Formik>
            </div>
        </div>
    )
}

export default Network;