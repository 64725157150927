import axios from "axios";
import { host } from "../shared/api";

const performLogin = async function (
  adminEmail: string,
  adminPassword: string
): Promise<string> {
  try {
    const response = await axios.post(`${host}/odin/auth/v1/login`, {
      adminEmail,
      adminPassword,
    });
    return response.data?.data;
  } catch (e) {
    console.log(e);
    return "";
  }
};

export { performLogin };
