import axios from "axios";
import { IUsers } from "./users.interface";
import { host } from "../shared/api";

const getUsers = async function (
  accountUuid: string,
  jwt: string
): Promise<IUsers[]> {
  try {
    const response = await axios.get(`${host}/odin/users/v1/users`, {
      params: { accountUuid: accountUuid || undefined },
      headers: { Authorization: `Bearer ${jwt}` },
    });
    return response.data?.data || [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

const generateUserJwt = async function (
  userUuid: string,
  jwt: string
): Promise<string> {
  try {
    const response = await axios.get(
      `${host}/odin/users/v1/users/jwt/${userUuid}`,
      { headers: { Authorization: `Bearer ${jwt}` } }
    );
    return response.data?.data || [];
  } catch (e) {
    console.log(e);
    return "";
  }
};

export { getUsers, generateUserJwt };
