import axios from "axios";
import { ISettings } from "./settings.interface";
import { host } from "../shared/api";

const getSettings = async function (jwt: string): Promise<ISettings[]> {
  try {
    const response = await axios.get(`${host}/odin/settings/v1/settings`, {
      headers: { Authorization: `Bearer ${jwt}` },
    });
    return response.data?.data || [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

const updateSettings = async function (
  jwt: string,
  data: ISettings
): Promise<boolean> {
  try {
    const response = await axios.put(
      `${host}/odin/settings/v1/settings`,
      data,
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
    return response?.data?.code === 200;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export { getSettings, updateSettings };
