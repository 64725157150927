import React, { useReducer, createContext, Dispatch } from 'react'
import { INetwork, INetworks } from './networks.interface';

type Action = { type: 'SET_NETWORKS', networks: INetworks[] } | { type: 'SET_NETWORK', network: INetwork };

const initialState: { networks: INetworks[], network?: INetwork } = {
  networks: [],
  network: {
    networkConnectionApiCountryCodeIsRequired: false,
    networkConnectionApiCountryCodeText: "",
    networkConnectionApiKeyIsRequired: false,
    networkConnectionApiKeyText: "",
    networkConnectionApiPartnerIdIsRequired: false,
    networkConnectionApiPartnerIdText: "",
    networkConnectionApiPasswordIsRequired: false,
    networkConnectionApiPasswordText: "",
    networkConnectionApiUsernameIsRequired: false,
    networkConnectionApiUsernameText: "",
    networkConnectionCallbackUrl: "",
    networkConnectionCpcCurrencyCodeIsRequired: false,
    networkConnectionCpcCurrencyCodeText: "",
    networkConnectionCpcValueIsRequired: false,
    networkConnectionCpcValueText: "",
    networkCreatedDatetime: "",
    networkIdentifier: "",
    networkIsActive: false,
    networkIsCustom: false,
    networkLogoUrl: "",
    networkName: "",
    networkSubTrackingIsActive: false,
    networkSupportsApiChannels: false,
    networkSupportsApiConversions: false,
    networkSupportsApiCpc: false,
    networkSupportsApiPaidConversions: false,
    networkSupportsApiPrograms: false,
    networkSupportsApiRejections: false,
    networkSupportsCallbacks: false,
    networkSupportsGlobalChannel: false,
    networkTrackingDomainSet: '',
    networkTrackingReplaceConnectionChannelId: '',
    networkTrackingReplaceClickUuid: '',
    networkTrackingReplaceDeeplinkUrl: '',
    networkTrackingTagClickUuid: '',
    networkTrackingTagDeeplinkUrl: '',
    networkTrackingTagConnectionChannelId: '',
    networkTrackingTagProgramId: '',
    networkCampaignClosedPath: '',
    networkUpdatedDatetime: "",
    networkUrl: "",
    networkUuid: "",
  }
};

const reducer = (state: typeof initialState = initialState, action: Action) => {
  switch (action.type) {
    case "SET_NETWORKS":
      state.networks = action.networks;
      return {...state};
    case "SET_NETWORK":
      state.network = action.network;
      return {...state};
    default:
      return state;
  }
};

const NetworkContext = createContext<{state: typeof initialState; dispatch: Dispatch<Action>}>({
  state: initialState,
  dispatch: () => null
});

const NetworksProvider: React.FC = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <NetworkContext.Provider value={value}>{props.children}</NetworkContext.Provider>
  );
}

export {
  NetworksProvider,
  NetworkContext,
  initialState,
}
