import axios from 'axios';
import {IAdmins} from './admins.interface';
import { host } from "../shared/api";

const getAdmins = async function (jwt: string): Promise<IAdmins[]> {
    try {
        const response = await axios.get(`${host}/odin/admins/v1/admins`,  {headers: {'Authorization': `Bearer ${jwt}`}});
        return response.data?.data || [];
    } catch (e) {
        console.log(e);
        return [];
    }
}

export {
    getAdmins,
}
