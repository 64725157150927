import React, {useContext, useEffect} from 'react';
import {NetworkContext} from './networks.context';
import {AuthContext} from '../auth/auth.context';
import {getNetwork, updateCustomNetwork} from './networks.api';
import Nav from '../nav/nav.component';
import { useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";

const Network = ({data}: any) => {
    const {state, dispatch} = useContext(NetworkContext);
    const authContext = useContext(AuthContext);
    const { networkUuid } = useParams<{ networkUuid: string }>();
    let buttonText = 'Submit';

    useEffect(() => {
        async function getNetworkFromApi() {
            const network = await getNetwork(authContext.state.jwt, networkUuid);
            if (network) {
                dispatch({
                    type: 'SET_NETWORK',
                    network,
                });
            }
        }

        async function startup() {
            await getNetworkFromApi();
        }


        startup();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (values: any) => {
        // handle form submission here
        const responseStatus = await updateCustomNetwork(authContext.state.jwt, {
            networkUuid,
            networkName: values.networkName || '',
            networkUrl: values.networkUrl || '',
            networkTrackingDomainSet: values.networkTrackingDomainSet || '',
            networkLogoUrl: values.networkLogoUrl || '',
            networkIsActive: values.networkIsActive || false,
            networkTrackingTagConnectionChannelId: values.networkTrackingTagConnectionChannelId || '',
            networkTrackingTagClickUuid: values.networkTrackingTagClickUuid || '',
            networkTrackingTagDeeplinkUrl: values.networkTrackingTagDeeplinkUrl || '',
            networkTrackingTagProgramId: values.networkTrackingTagProgramId || '',
            networkConnectionCallbackUrl: values.networkConnectionCallbackUrl || '',
            networkTrackingReplaceConnectionChannelId: values.networkTrackingReplaceConnectionChannelId || '',
            networkTrackingReplaceDeeplinkUrl: values.networkTrackingReplaceDeeplinkUrl || '',
            networkTrackingReplaceClickUuid: values.networkTrackingReplaceClickUuid || '',
            networkCampaignClosedPath: values.networkCampaignClosedPath || '',
        });
        if (responseStatus) {
            buttonText = 'Saved';
        } else {
            buttonText = 'Error';
        }
      };

    if (state.network?.networkUuid !== networkUuid) {
        return <div>loading</div>
    }

    return (
        <div>
            <Nav />
            <div className="container">
                <h1>Network</h1>
                <p>UUID: {state.network.networkUuid}</p>
                <p>Updated: {state.network.networkUpdatedDatetime}</p>
                <p>Connection to API | country code is required: {state.network.networkConnectionApiCountryCodeIsRequired}</p>
                <p>Connection to API | country code text: {state.network.networkConnectionApiCountryCodeText}</p>
                <p>Connection to API | API key is required: {state.network.networkConnectionApiKeyIsRequired}</p>
                <p>Connection to API | API key text: {state.network.networkConnectionApiKeyText}</p>
                <p>Connection to API | Partner ID is required: {state.network.networkConnectionApiPartnerIdIsRequired}</p>
                <p>Connection to API | Partner ID text: {state.network.networkConnectionApiPartnerIdText}</p>
                <p>Connection to API | Password is required: {state.network.networkConnectionApiPasswordIsRequired}</p>
                <p>Connection to API | Password text: {state.network.networkConnectionApiPasswordText}</p>
                <p>Connection to API | Username is required: {state.network.networkConnectionApiUsernameIsRequired}</p>
                <p>Connection to API | Username text: {state.network.networkConnectionApiUsernameText}</p>
                <p>Connection to API | CPC Currency Code is required: {state.network.networkConnectionCpcCurrencyCodeIsRequired}</p>
                <p>Connection to API | CPC Currency Code text : {state.network.networkConnectionCpcCurrencyCodeText}</p>
                <p>Connection to API | CPC value is required: {state.network.networkConnectionCpcValueIsRequired}</p>
                <p>Connection to API | CPC value text: {state.network.networkConnectionCpcValueText}</p>
                <p>Created: {state.network.networkCreatedDatetime}</p>
                <p>Identifier: {state.network.networkIdentifier}</p>
                <p>Is custom network: {state.network.networkIsCustom}</p>
                <p>Sub tracking is active: {state.network.networkSubTrackingIsActive}</p>
                <p>Supports channels: {state.network.networkSupportsApiChannels}</p>
                <p>Supports conversions: {state.network.networkSupportsApiConversions}</p>
                <p>Supports CPC: {state.network.networkSupportsApiCpc}</p>
                <p>Supports paid conversions: {state.network.networkSupportsApiPaidConversions}</p>
                <p>Supports programs: {state.network.networkSupportsApiPrograms}</p>
                <p>Supports rejections: {state.network.networkSupportsApiRejections}</p>
                <p>Supports callback: {state.network.networkSupportsCallbacks}</p>
                <p>Supports global channel: {state.network.networkSupportsGlobalChannel}</p>

                {state.network?.networkIsCustom &&
                    <div>
                        is Custom
                    </div>
                }
                <Formik
                    initialValues={{
                        networkName: state.network.networkName,
                        networkUrl: state.network.networkUrl,
                        networkIsActive: !!state.network.networkIsActive,
                        networkLogoUrl: state.network.networkLogoUrl,
                        networkTrackingDomainSet: state.network.networkTrackingDomainSet,
                        networkTrackingTagConnectionChannelId: state.network.networkTrackingTagConnectionChannelId,
                        networkTrackingTagClickUuid: state.network.networkTrackingTagClickUuid,
                        networkTrackingTagDeeplinkUrl: state.network.networkTrackingTagDeeplinkUrl,
                        networkTrackingTagProgramId: state.network.networkTrackingTagProgramId,
                        networkConnectionCallbackUrl: state.network.networkConnectionCallbackUrl,
                        networkTrackingReplaceConnectionChannelId: state.network.networkTrackingReplaceConnectionChannelId,
                        networkTrackingReplaceClickUuid: state.network.networkTrackingReplaceClickUuid,
                        networkTrackingReplaceDeeplinkUrl: state.network.networkTrackingReplaceDeeplinkUrl,
                        networkCampaignClosedPath: state.network.networkCampaignClosedPath,
                    }}
                    onSubmit={handleSubmit}
                    >

                    {({ values, handleChange, setFieldValue }) => (
                        <Form className="form">
                        <label htmlFor="network-name">Name:</label>
                        <Field
                            type="text"
                            id="network-name"
                            name="networkName"
                        />

                        <label htmlFor="network-url">Url:</label>
                        <Field
                            type="text"
                            id="network-url"
                            name="networkUrl"
                        />

                        <label htmlFor="network-is-active">Network is active:</label>
                        <Field
                            type="checkbox"
                            id="network-is-active"
                            name="networkIsActive"
                            checked={values.networkIsActive}
                            onChange={() => setFieldValue('networkIsActive', !values.networkIsActive)}
                        />

                        <label htmlFor="network-logo-url">
                            networkLogoUrl:
                        </label>
                        <Field
                            type="text"
                            id="network-logo-url"
                            name="networkLogoUrl"
                        />


                        <label htmlFor="network-tracking-domain-set">Tracking domain set (comma separated):</label>
                        <Field
                            type="text"
                            id="network-tracking-domain-set"
                            name="networkTrackingDomainSet"
                        />

                        <label htmlFor="network-tracking-tag-connection-channel-id">Network query param for channel id:</label>
                        <Field
                            type="text"
                            id="network-tracking-tag-connection-channel-id"
                            name="networkTrackingTagConnectionChannelId"
                        />

                        <label htmlFor="network-tracking-tag-click-uuid">Network query param for clickUuid/sub:</label>
                        <Field
                            type="text"
                            id="network-tracking-tag-click-uuid"
                            name="networkTrackingTagClickUuid"
                        />

                        <label htmlFor="network-tracking-tag-deeplink-url">Network query param for deeplink:</label>
                        <Field
                            type="text"
                            id="network-tracking-tag-deeplink-url"
                            name="networkTrackingTagDeeplinkUrl"
                        />

                        <label htmlFor="network-tracking-tag-program-id">Network query param for program id:</label>
                        <Field
                            type="text"
                            id="network-tracking-tag-program-id"
                            name="networkTrackingTagProgramId"
                        />

                        <label htmlFor="network-connection-callback-url">Network callback url:</label>
                        {/* <small>?clickUuid={sub}&payout={payout}&sale={sale_amount}&transactionId={unique_transaction_id}&currencyCode={currencyCode}</small> */}
                        <Field
                            type="text"
                            id="network-connection-callback-url"
                            name="networkConnectionCallbackUrl"
                        />

                        <label htmlFor="network-tracking-replace-click-uuid">Network path for clickUuid/sub (/sub:{}):</label>
                        <Field
                            type="text"
                            id="network-tracking-replace-click-uuid"
                            name="networkTrackingReplaceClickUuid"
                        />

                        <label htmlFor="network-tracking-replace-deeplink-url">Network path for clickUuid/sub (/url:{}):</label>
                        <Field
                            type="text"
                            id="network-tracking-replace-deeplink-url"
                            name="networkTrackingReplaceDeeplinkUrl"
                        />

                        <label htmlFor="network-tracking-replace-connection-channel-id">Network path for channel id (/media:{}):</label>
                        <Field
                            type="text"
                            id="network-tracking-replace-connection-channel-id"
                            name="networkTrackingReplaceConnectionChannelId"
                        />

                        <label htmlFor="network-campaign-closed-path">Network domain or path of closed campaign:</label>
                        <Field
                            type="text"
                            id="network-campaign-closed-path"
                            name="networkCampaignClosedPath"
                        />

                        <button type="submit">{buttonText}</button>
                        </Form>
                    )}
                    </Formik>
            </div>
        </div>
    )
}

export default Network;
