import React, { useContext, useEffect } from "react";
import { UserContext } from "./users.context";
import { AuthContext } from "../auth/auth.context";
import { getUsers, generateUserJwt } from "./users.api";
import Nav from "../nav/nav.component";

const Users = ({ data }: any) => {
  const { state, dispatch } = useContext(UserContext);
  const authContext = useContext(AuthContext);

  const search = new URLSearchParams(window.location.search);
  const accountUuid = search.get("accountUuid");

  useEffect(() => {
    async function getUsersFromApi() {
      const users = await getUsers(accountUuid, authContext.state.jwt);
      dispatch({
        type: "SET_USERS",
        users,
      });
    }

    async function startup() {
      await getUsersFromApi();
    }

    startup();
    // eslint-disable-next-line
  }, [accountUuid]);

  const loginAs = async (userUuid: string) => {
    console.log("login as", userUuid);
    const jwt = await generateUserJwt(userUuid, authContext.state.jwt);
    if (jwt) {
      // open new tab and go to https://outbound.staging.gowish.com/?jwt=${jwt}
      window.open(`https://outbound.heylink.com/auth/jwt/${jwt}`, "_blank");
    }
  };

  return (
    <div>
      <Nav />
      <div className="container users-list">
        <table>
          <thead>
            <tr>
              <th>id</th>
              <th>email</th>
              <th>name</th>
              <th>connections</th>
              <th>commission</th>
              <th>account name</th>
              <th>account email</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            {state.users.map((user, index) => {
              return (
                <tr key={`user-${index}`}>
                  <td>{user.userUuid}</td>
                  <td>{user.userEmail}</td>
                  <td>
                    {user.userFirstname} {user.userLastname}
                  </td>
                  <td>{user.connectionsCount}</td>
                  <td>
                    {user.commission} {user.currencyCode}
                  </td>
                  <td>{user.account.accountName}</td>
                  <td>{user.account.accountEmail}</td>
                  <td>
                    <button onClick={() => loginAs(user.userUuid)}>
                      login as
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
