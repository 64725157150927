import React, { useReducer, createContext, Dispatch } from "react";
import { ISettings } from "./settings.interface";

type Action = { type: "SET_SETTINGS"; settings: ISettings };

const initialState: { settings: ISettings[] } = {
  opportunityBlacklistDomains: [],
};

const reducer = (state: typeof initialState = initialState, action: Action) => {
  switch (action.type) {
    case "SET_SETTINGS":
      state.settings = action.settings;
      const newState = { ...state };
      return newState;
    default:
      return state;
  }
};

const SettingsContext = createContext<{
  state: typeof initialState;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

const SettingsProvider: React.FC = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <SettingsContext.Provider value={value}>
      {props.children}
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, SettingsContext, initialState };
