import React, { useReducer, createContext, Dispatch } from "react";
// import {IConversion, PageviewDTO, DiscoverDTO, CpcDTO} from '../../logic/interface';
// import { IConversion } from '../conversions/conversions.interface';
import cookie from "js-cookie";

type Action = { type: "SET_JWT"; jwt: string };

const initialState: { jwt: string } = {
  jwt: cookie.get("jwt") || "",
};

const reducer = (state: typeof initialState = initialState, action: Action) => {
  switch (action.type) {
    case "SET_JWT":
      state.jwt = action.jwt;
      console.log(state);
      if (action.jwt) {
        cookie.set("jwt", action.jwt);
      }
      const newState1 = { ...state };
      return newState1;
    case "REMOVE_JWT":
      state.jwt = "";
      cookie.remove("jwt");
      const newState2 = { ...state };
      return newState2;
    default:
      return state;
  }
};

const AuthContext = createContext<{
  state: typeof initialState;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

const AuthProvider: React.FC = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext, initialState };
