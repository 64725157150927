import React, { useContext, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { sortBy } from "lodash";
import moment from "moment";
import { StatusContext } from "./status.context";
import { AuthContext } from "../auth/auth.context";
import { getStatus } from "./status.api";
import Nav from "../nav/nav.component";

const Status = ({ data }: any) => {
  const { state, dispatch } = useContext(StatusContext);
  const authContext = useContext(AuthContext);

  const [tab, setTab] = useState("tracker");

  useEffect(() => {
    async function getStatusFromApi() {
      const status = await getStatus(authContext.state.jwt);
      dispatch({
        type: "SET_STATUS",
        status,
      });
    }

    async function startup() {
      await getStatusFromApi();
    }

    startup();
    // eslint-disable-next-line
  }, []);

  if (!state.status) return null;

  return (
    <div>
      <Nav />
      <div className="container status-list">
        <h1>Status</h1>
        <div style={{ marginBottom: 16 }}>
          <a
            href="#trackerReport"
            style={{
              textDecoration: tab === "tracker" ? "underline" : "none",
            }}
            onClick={(event) => {
              event.preventDefault();
              setTab("tracker");
            }}
          >
            Closed campaigns with clicks in last 7 days
          </a>
          &nbsp;&nbsp;·&nbsp;&nbsp;
          <a
            href="#networkReport"
            style={{
              textDecoration: tab === "network" ? "underline" : "none",
            }}
            onClick={(event) => {
              event.preventDefault();
              setTab("network");
            }}
          >
            Network import report
          </a>
        </div>

        {tab === "tracker" && (
          <div>
            <h3 id="trackerReport">
              Closed campaigns with clicks in last 7 days
            </h3>
            <TrackersReport report={state.status.trackersReport || []} />
          </div>
        )}
        {tab === "network" && (
          <div>
            <h3 id="networkReport">Network import report</h3>
            <NetworkReport report={state.status.networksReport || []} />
          </div>
        )}
      </div>
    </div>
  );
};

const TrackersReport = (props) => {
  const { report } = props;

  const shownReport = useMemo(() => {
    return sortBy(report, [
      "trackerAccountUuid",
      "trackerNetworkUuid",
      "trackerUpdatedDatetime",
    ]);
  }, [report]);

  let lastAccount = "";
  let lastNetwork = "";

  return (
    <table>
      <thead>
        <tr>
          <th>Account</th>
          <th>Network</th>
          <th>Campaign</th>
          <th>Last update at</th>
          <th>Clicks</th>
        </tr>
      </thead>
      <tbody>
        {shownReport.map((report) => {
          const hideAccount = lastAccount === report.trackerAccountUuid;
          const hideNetwork =
            lastNetwork === report.trackerNetworkUuid &&
            lastAccount === report.trackerAccountUuid;
          return (
            <tr key={report.trackerUuid}>
              <td>
                {hideAccount
                  ? null
                  : (lastAccount = report.trackerAccountUuid) && (
                      <Link to={`/accounts/${report.trackerAccountUuid}`}>
                        {report.account?.accountName || "Unknown account"}
                      </Link>
                    )}
              </td>
              <td>
                {hideNetwork
                  ? null
                  : (lastNetwork = report.trackerNetworkUuid) && (
                      <Link to={`/networks/${report.trackerNetworkUuid}`}>
                        {report.network?.networkName || "Unknown network"}
                      </Link>
                    )}
              </td>
              <td>
                {report.trackerUrlMatch} - {report.trackerName}
              </td>
              <td>
                {moment(report.trackerUpdatedDatetime).format(
                  "YYYY-MM-DD HH:mm:ss",
                )}
              </td>
              <td>{report.clickCount} clicks</td>
            </tr>
          );
        })}
        {!shownReport.length && (
          <tr>
            <td colSpan={10}>
              <div
                style={{
                  textAlign: "center",
                  fontStyle: "italic",
                  fontWeight: 600,
                  opacity: 0.5,
                }}
              >
                Nothing found
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const NetworkReport = (props) => {
  const { report } = props;

  const [showMore, setShowMore] = useState(true);
  const [showDate, setShowDate] = useState("");
  const dates = useMemo(
    () => ({
      today: moment().format("YYYY-MM-DD"),
      yesterday: moment().subtract(1, "day").format("YYYY-MM-DD"),
    }),
    [],
  );

  const shownReport = useMemo(() => {
    const sorted = sortBy(report, (report) => [
      report.accountUuid,
      report.connectionUuid,
      report.network?.networkUuid,
      report.date,
    ]);

    let shown = [];
    for (const indexStr in sorted) {
      const index = parseInt(indexStr);

      let show = false;

      if (showDate) {
        if (sorted[index].date !== showDate) continue;
      }

      if (sorted[index].error) {
        sorted[index].reason = "Error";
        show = true;
        if (index > 0) {
          if (
            sorted[index].connectionUuid === sorted[index - 1].connectionUuid &&
            sorted[index].error &&
            sorted[index - 1].error &&
            sorted[index].value === sorted[index - 1].value
          ) {
            shown.pop();
          }
        }
      } else if (index > 0) {
        if (
          sorted[index].connectionUuid === sorted[index - 1].connectionUuid &&
          !sorted[index].error &&
          !sorted[index - 1].error &&
          sorted[index].value &&
          sorted[index - 1].value
          // sorted[index].value <= sorted[index - 1].value * 0.9
        ) {
          if (sorted[index].value < sorted[index - 1].value) {
            sorted[index].reason = "Import decline";
            sorted[index].shownValue = `${sorted[index].value} programs (${
              sorted[index].value - sorted[index - 1].value
            })`;
            if (showMore) {
              show = true;
            }
          } else if (sorted[index].value > sorted[index - 1].value) {
            sorted[index].reason = "Import increase";
            sorted[index].shownValue = `${sorted[index].value} programs (+${
              sorted[index].value - sorted[index - 1].value
            })`;
            if (showMore) {
              show = true;
            }
          }
        }
      }

      if (show) {
        if (!showMore && !sorted[index].error) {
          let shownIndexToRemove = shown.findIndex(
            (item) =>
              item.error &&
              item.connectionUuid === sorted[index].connectionUuid,
          );
          while (shownIndexToRemove !== -1) {
            shown.splice(shownIndexToRemove, 1);
            shownIndexToRemove = shown.findIndex(
              (item) =>
                item.error &&
                item.connectionUuid === sorted[index].connectionUuid,
            );
          }
        }

        shown.push(sorted[index]);
      }
    }
    return shown;
  }, [report, showMore, showDate]);

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Account</th>
            <th>Connection</th>
            <th>Network</th>
            <th>Date</th>
            <th>Info</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {shownReport.map((report) => (
            <tr
              key={
                report.accountUuid +
                report.connectionUuid +
                report.network?.networkUuid +
                report.date
              }
              style={{
                display:
                  !showMore && report.value === "No connection mappings"
                    ? "none"
                    : "table-row",
              }}
            >
              <td style={{ whiteSpace: "nowrap" }}>
                <Link to={`/accounts/${report.accountUuid}`}>
                  {report.account?.accountName || "Unknown account"}
                </Link>
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                {report.connection?.connectionName ||
                  report.connection?.connectionUuid ||
                  "Unknown connection"}
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                <Link to={`/networks/${report.network?.networkUuid}`}>
                  {report.network?.networkName || "Unknown network"}
                </Link>
              </td>
              <td style={{ whiteSpace: "nowrap" }}>{report.date}</td>
              <td style={{ whiteSpace: "nowrap" }}>{report.reason}</td>
              <td>{report.shownValue || report.value}</td>
            </tr>
          ))}
          {!shownReport.length && (
            <tr>
              <td colSpan={10}>
                <div
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: 600,
                    opacity: 0.5,
                  }}
                >
                  Nothing found
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <p>
        <button onClick={() => setShowMore(!showMore)}>
          {showMore ? "Show less" : "Show more"}
        </button>
        &nbsp; &nbsp; &nbsp;
        <button
          onClick={() => {
            if (!showDate) {
              setShowDate(dates.today);
            } else if (showDate === dates.today) {
              setShowDate(dates.yesterday);
            } else if (showDate === dates.yesterday) {
              setShowDate("");
            } else {
              setShowDate("");
            }
          }}
        >
          {!showDate && "Show today"}
          {showDate === dates.today && "Show yesterday"}
          {showDate === dates.yesterday && "Show all"}
        </button>
      </p>
    </>
  );
};

export default Status;
