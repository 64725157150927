import React, {useContext, useEffect} from 'react';
import {AuthContext} from './auth.context';
import {performLogin} from './auth.api';
import { useForm } from 'react-hook-form';
import Nav from '../nav/nav.component';

const Plugins = ({data}: any) => {

    const {state, dispatch} = useContext(AuthContext);
    
    const { handleSubmit, register } = useForm({
        defaultValues: {adminEmail: '', adminPassword: ''},
    });

    useEffect(() => {

    }, [dispatch]);

    const save = async (d: any) => {
        const jwt = await performLogin(d.adminEmail, d.adminPassword);
        if (jwt) {
            dispatch({
                type: 'SET_JWT',
                jwt,
            });
        }
    }

    if (state.jwt) {
        return <Nav/>;
    }

    return (
        <div>
            <div className="half">
                <form onSubmit={handleSubmit(save)}>
                    <div className="box">
                        email <br/>
                        <input type="text" {...register('adminEmail')} />
                    </div>
                    <div className="box">
                        password <br/>
                        <input type="password" {...register('adminPassword')} />
                    </div>
                    <button type="submit">Save</button>
                </form>
            </div>
        </div>
    )
}
export default Plugins;