import React, { useReducer, createContext, Dispatch } from 'react'
import { IAccounts, IAccount } from './accounts.interface';

type Action = { type: 'SET_ACCOUNTS', accounts: IAccounts[] } | { type: 'SET_ACCOUNT', account: IAccount };

const initialState: { accounts: IAccounts[], account: IAccount } = {
  accounts: [],
  account: {
    accountAddress: '',
    accountName: '',
    accountUuid: '',
    accountBillingActiveMrr: 0,
    accountBillingChurnDatetime: '',
    accountBillingCustomerId: '',
    accountBillingFrequency: '',
    accountBillingNextPaymentDatetime: '',
    accountBillingPaymentStatus: '',
    accountBillingSubscriptionChangeDatetime: '',
    accountBillingSubscriptionEndDatetime: '',
    accountBillingSubscriptionIsActive: false,
    accountBillingSubscriptionProductPlan: '',
    accountBillingSubscriptionStatus: '',
    accountFeatureFlagLinkScanner: false,
    accountFeatureFlagBrokenLinks: false,
    accountFeatureFlagRevshare: false,
    accountLinkScannerLimit: 500,
    accountLinkScannerHours: 24,
    accountCity: '',
    accountCountryCode: '',
    accountEmail: '',
    accountIsActive: false,
    accountIsTrackingActive: false,
    accountPhone: '',
    accountUrl: '',
    accountVatNumber: '',
    accountZipcode: '',
  }
};

const reducer = (state: typeof initialState = initialState, action: Action) => {
  switch (action.type) {
    case "SET_ACCOUNTS":
      state.accounts = action.accounts;
      return {...state};
    case "SET_ACCOUNT":
      state.account = action.account;
      return {...state};
    default:
      return state;
  }
};

const AccountContext = createContext<{state: typeof initialState; dispatch: Dispatch<Action>}>({
  state: initialState,
  dispatch: () => null
});

const AccountsProvider: React.FC = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <AccountContext.Provider value={value}>{props.children}</AccountContext.Provider>
  );
}

export {
  AccountsProvider,
  AccountContext,
  initialState,
}
