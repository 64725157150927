import React from "react";
import Login from "./components/auth/login.component";
import Admins from "./components/admins/admins.component";
import Users from "./components/users/users.component";
import Accounts from "./components/accounts/accounts.component";
import Account from "./components/accounts/account.component";
import Networks from "./components/networks/networks.component";
import Network from "./components/networks/network.component";
import CreateNetwork from "./components/networks/create.network.component";
import Status from "./components/status/status.component";
import Settings from "./components/settings/settings.component";

import { AuthProvider } from "./components/auth/auth.context";
import { AdminsProvider } from "./components/admins/admins.context";
import { UsersProvider } from "./components/users/users.context";
import { AccountsProvider } from "./components/accounts/accounts.context";
import { NetworksProvider } from "./components/networks/networks.context";
import { StatusProvider } from "./components/status/status.context";
import { SettingsProvider } from "./components/settings/settings.context";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const App = () => {
  return (
    <AuthProvider>
      <AdminsProvider>
        <UsersProvider>
          <AccountsProvider>
            <NetworksProvider>
              <SettingsProvider>
                <StatusProvider>
                  <Router>
                    <Switch>
                      <Route exact path="/" component={Login} />
                      <Route exact path="/admins" component={Admins} />
                      <Route exact path="/users" component={Users} />
                      <Route exact path="/accounts" component={Accounts} />
                      <Route
                        path="/accounts/:accountUuid"
                        component={Account}
                      />
                      <Route exact path="/networks" component={Networks} />
                      <Route
                        exact
                        path="/networks/create"
                        component={CreateNetwork}
                      />
                      <Route
                        path="/networks/:networkUuid"
                        component={Network}
                      />
                      <Route path="/status" component={Status} />
                      <Route path="/settings" component={Settings} />
                    </Switch>
                  </Router>
                </StatusProvider>
              </SettingsProvider>
            </NetworksProvider>
          </AccountsProvider>
        </UsersProvider>
      </AdminsProvider>
    </AuthProvider>
  );
};

export default App;
