import axios from 'axios';
import {INetworks, INetwork, ICreateCustomNetwork, IUpdateCustomNetwork} from './networks.interface';
import { host } from "../shared/api";

const getNetworks = async function (jwt: string): Promise<INetworks[]> {
    try {
        const response = await axios.get(`${host}/odin/networks/v1/networks`,  {headers: {'Authorization': `Bearer ${jwt}`}});
        return response.data?.data || [];
    } catch (e) {
        console.log(e);
        return [];
    }
}

const getNetwork = async function (jwt: string, networkUuid: string): Promise<INetwork | undefined> {
    try {
        const response = await axios.get(`${host}/odin/networks/v1/networks/${networkUuid}`,  {headers: {'Authorization': `Bearer ${jwt}`}});
        return response.data?.data || {};
    } catch (e) {
        console.log(e);
        return;
    }
}
const updateCustomNetwork = async function (jwt: string, data: IUpdateCustomNetwork): Promise<boolean> {
    try {
        const {networkUuid, ...rest} = data;
        const response = await axios.put(`${host}/odin/networks/v1/networks/${networkUuid}`, rest, {headers: {'Authorization': `Bearer ${jwt}`}});
        return response?.data?.code === 200;
    } catch (e) {
        console.log(e);
        return false;
    }
}

const createCustomNetwork = async function (jwt: string, data: ICreateCustomNetwork): Promise<string> {
    try {
        const response = await axios.post(`${host}/odin/networks/v1/networks/custom`, data, {headers: {'Authorization': `Bearer ${jwt}`}});
        return response?.data?.data;
    } catch (e) {
        console.log(e);
        return '';
    }
}

export {
    getNetworks,
    getNetwork,
    updateCustomNetwork,
    createCustomNetwork,
}
