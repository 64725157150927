import axios from "axios";
import {
  IAccounts,
  IAccount,
  IUpdateAccountBilling,
  IUpdateAccountFeatures,
} from "./accounts.interface";
import { host } from "../shared/api";

const getAccounts = async function (jwt: string): Promise<IAccounts[]> {
  try {
    const response = await axios.get(`${host}/odin/accounts/v1/accounts`, {
      headers: { Authorization: `Bearer ${jwt}` },
    });
    return response.data?.data || [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

const getAccount = async function (
  jwt: string,
  accountUuid: string
): Promise<IAccount> {
  try {
    const response = await axios.get(
      `${host}/odin/accounts/v1/accounts/${accountUuid}`,
      { headers: { Authorization: `Bearer ${jwt}` } }
    );
    return response.data?.data || {};
  } catch (e) {
    console.log(e);
    return {
      accountUuid: "",
      accountName: "",
      accountAddress: "",
      accountBillingActiveMrr: 0,
      accountBillingChurnDatetime: "",
      accountBillingCustomerId: "",
      accountBillingFrequency: "",
      accountBillingNextPaymentDatetime: "",
      accountBillingPaymentStatus: "",
      accountBillingSubscriptionChangeDatetime: "",
      accountBillingSubscriptionEndDatetime: "",
      accountBillingSubscriptionIsActive: false,
      accountBillingSubscriptionProductPlan: "",
      accountBillingSubscriptionStatus: "",
      accountFeatureFlagLinkScanner: false,
      accountFeatureFlagBrokenLinks: false,
      accountFeatureFlagRevshare: false,
      accountLinkScannerLimit: 500,
      accountLinkScannerHours: 24,
      accountCity: "",
      accountCountryCode: "",
      accountEmail: "",
      accountIsActive: false,
      accountIsTrackingActive: false,
      accountTrackingParams: "",
      accountPhone: "",
      accountUrl: "",
      accountVatNumber: "",
      accountZipcode: "",
    };
  }
};

const updateAccountBilling = async function (
  jwt: string,
  accountUuid: string,
  data: IUpdateAccountBilling
): Promise<boolean> {
  try {
    const response = await axios.put(
      `${host}/odin/accounts/v1/accounts/${accountUuid}/billing`,
      data,
      { headers: { Authorization: `Bearer ${jwt}` } }
    );
    return response?.data?.code === 200;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const updateAccountFeatures = async function (
  jwt: string,
  accountUuid: string,
  data: IUpdateAccountFeatures
): Promise<boolean> {
  try {
    const response = await axios.put(
      `${host}/odin/accounts/v1/accounts/${accountUuid}/featureflags`,
      data,
      { headers: { Authorization: `Bearer ${jwt}` } }
    );
    return response?.data?.code === 200;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const reimportAccountPrograms = async function (
  jwt: string,
  accountUuid: string,
  connectionUuid: string
): Promise<boolean> {
  try {
    const response = await axios.post(
      `${host}/odin/accounts/v1/accounts/${accountUuid}/importprograms`,
      { connectionUuid },
      { headers: { Authorization: `Bearer ${jwt}` } }
    );
    return response?.data?.code === 200;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export {
  getAccounts,
  getAccount,
  updateAccountBilling,
  updateAccountFeatures,
  reimportAccountPrograms,
};
