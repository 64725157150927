import React, { useContext, useEffect } from "react";
import { AccountContext } from "./accounts.context";
import { AuthContext } from "../auth/auth.context";
import { getAccounts } from "./accounts.api";
import Nav from "../nav/nav.component";
import { Link } from "react-router-dom";

const Accounts = ({ data }: any) => {
  const { state, dispatch } = useContext(AccountContext);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    async function getAccountsFromApi() {
      const accounts = await getAccounts(authContext.state.jwt);
      dispatch({
        type: "SET_ACCOUNTS",
        accounts,
      });
    }

    async function startup() {
      await getAccountsFromApi();
    }

    startup();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Nav />
      <div className="container accounts-list">
        <table>
          <thead>
            <tr>
              <th>account uuid</th>
              <th>name</th>
              <th>email</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            {state.accounts.map((account, index) => {
              return (
                <tr key={`account-${index}`}>
                  <td>{account.accountUuid}</td>
                  <td>{account.accountName}</td>
                  <td>{account.accountEmail}</td>
                  <td>
                    <Link to={`/users?accountUuid=${account.accountUuid}`}>Users</Link>
                    &nbsp; &nbsp;
                    <Link to={`/accounts/` + account.accountUuid}>View</Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Accounts;
