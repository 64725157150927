import React, { useState, useContext, useEffect, useCallback } from "react";
import { AccountContext } from "./accounts.context";
import { AuthContext } from "../auth/auth.context";
import {
  getAccount,
  updateAccountBilling,
  updateAccountFeatures,
  reimportAccountPrograms,
} from "./accounts.api";
import Nav from "../nav/nav.component";
import { useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Accounts = ({ data }: any) => {
  const { state, dispatch } = useContext(AccountContext);
  const authContext = useContext(AuthContext);
  const { accountUuid } = useParams<{ accountUuid: string }>();

  useEffect(() => {
    async function getAccountFromApi() {
      const account = await getAccount(authContext.state.jwt, accountUuid);
      dispatch({
        type: "SET_ACCOUNT",
        account,
      });
    }

    async function startup() {
      await getAccountFromApi();
    }

    startup();
    // eslint-disable-next-line
  }, []);

  if (state.account.accountUuid !== accountUuid) {
    return <div>loading</div>;
  }

  return (
    <div>
      <Nav />
      <div className="container">
        <h1>Account</h1>
        <p>Account UUID: {state.account.accountUuid}</p>
        <p>Account Name: {state.account.accountName}</p>
        <p>Account Address: {state.account.accountAddress}</p>
        <p>Account City: {state.account.accountCity}</p>
        <p>Account Country Code: {state.account.accountCountryCode}</p>
        <p>Account Email: {state.account.accountEmail}</p>
        <p>Account Is Active: {state.account.accountIsActive}</p>
        <p>
          Account Is Tracking Active: {state.account.accountIsTrackingActive}
        </p>
        <p>
          Account Inbound Custom Params: {state.account.accountTrackingParams}
        </p>
        <p>Account Phone: {state.account.accountPhone}</p>
        <p>Account URL: {state.account.accountUrl}</p>
        <p>Account VAT Number: {state.account.accountVatNumber}</p>
        <p>Account Zipcode: {state.account.accountZipcode}</p>
        <h2>Stripe billing</h2>
        <p>
          Account Billing Subscription End Datetime:{" "}
          {state.account.accountBillingSubscriptionEndDatetime}
        </p>
        <p>
          Account Billing Subscription Is Active:{" "}
          {state.account.accountBillingSubscriptionIsActive}
        </p>
        <p>
          Account Billing Subscription Product Plan:{" "}
          {state.account.accountBillingSubscriptionProductPlan}
        </p>
        <p>
          Account Billing Subscription Status:{" "}
          {state.account.accountBillingSubscriptionStatus}
        </p>
        <div className="row">
          <BillingForm {...{ state, authContext, accountUuid }} />
          <FeaturesForm {...{ state, authContext, accountUuid }} />
        </div>
        <br />
        <div className="row">
          <NetworksForm {...{ state, authContext, accountUuid }} />
        </div>
      </div>
    </div>
  );
};

const BillingForm = ({ state, authContext, accountUuid }) => {
  let buttonText = "Submit";

  const handleSubmit = async (values: any) => {
    // handle form submission here
    const responseStatus = await updateAccountBilling(
      authContext.state.jwt,
      accountUuid,
      {
        accountBillingCustomerId: values.accountBillingCustomerId,
        accountBillingActiveMrr: values.accountBillingActiveMrr,
        accountBillingChurnDate: moment(
          values.accountBillingChurnDatetime,
        ).format("YYYY-MM-DD"),
        accountBillingFrequency: values.accountBillingFrequency,
        accountBillingNextPaymentDate: moment(
          values.accountBillingNextPaymentDatetime,
        ).format("YYYY-MM-DD"),
        accountBillingPaymentStatus: values.accountBillingPaymentStatus,
        accountBillingSubscriptionChangeDate: moment(
          values.accountBillingSubscriptionChangeDatetime,
        ).format("YYYY-MM-DD"),
      },
    );
    if (responseStatus) {
      buttonText = "Saved";
    } else {
      buttonText = "Error";
    }
  };

  return (
    <div className="flex-1">
      <h2>Custom billing information</h2>
      <Formik
        initialValues={{
          accountBillingCustomerId: state.account.accountBillingCustomerId,
          accountBillingActiveMrr: state.account.accountBillingActiveMrr,
          accountBillingChurnDatetime: new Date(
            state.account.accountBillingChurnDatetime,
          ),
          accountBillingFrequency: state.account.accountBillingFrequency,
          accountBillingNextPaymentDatetime: new Date(
            state.account.accountBillingNextPaymentDatetime,
          ),
          accountBillingPaymentStatus:
            state.account.accountBillingPaymentStatus,
          accountBillingSubscriptionChangeDatetime: new Date(
            state.account.accountBillingSubscriptionChangeDatetime,
          ),
        }}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form className="form">
            <label htmlFor="account-billing-customer-id">
              Account Billing Customer ID (Stripe):
            </label>
            <Field
              type="text"
              id="account-billing-customer-id"
              name="accountBillingCustomerId"
            />

            <label htmlFor="account-billing-active-mrr">
              Account Billing Active MRR:
            </label>
            <Field
              type="text"
              id="account-billing-active-mrr"
              name="accountBillingActiveMrr"
            />

            <label htmlFor="account-billing-frequency">
              Account Billing Frequency:
            </label>
            <Field
              as="select"
              id="account-billing-frequency"
              name="accountBillingFrequency"
            >
              <option value="">Select a frequency</option>
              <option value="monthly">Monthly</option>
              <option value="quarterly">Quarterly</option>
              <option value="yearly">Yearly</option>
            </Field>

            <label htmlFor="account-billing-payment-status">
              Account Billing Payment Status:
            </label>
            <Field
              as="select"
              id="account-billing-payment-status"
              name="accountBillingPaymentStatus"
            >
              <option value="">Select a status</option>
              <option value="paid">Paid</option>
              <option value="overdue">Overdue</option>
              <option value="churned">Churned</option>
            </Field>

            <label htmlFor="account-billing-next-payment-date">
              Account Billing Next Payment Date:
            </label>
            <DatePicker
              selected={values.accountBillingNextPaymentDatetime}
              onChange={(date: Date) =>
                setFieldValue("accountBillingNextPaymentDatetime", date)
              }
              dateFormat="yyyy-MM-dd"
              id="account-billing-next-payment-datetime"
              name="accountBillingNextPaymentDatetime"
            />

            <label htmlFor="account-billing-churn-datetime">
              Account Billing Churn Datetime:
            </label>
            <DatePicker
              selected={values.accountBillingChurnDatetime}
              onChange={(date: Date) =>
                setFieldValue("accountBillingChurnDatetime", date)
              }
              dateFormat="yyyy-MM-dd"
              id="account-billing-churn-datetime"
              name="accountBillingChurnDatetime"
            />

            <label htmlFor="account-billing-subscription-change-datetime">
              Account Billing Subscription Change Datetime:
            </label>
            <DatePicker
              selected={values.accountBillingSubscriptionChangeDatetime}
              onChange={(date: Date) =>
                setFieldValue("accountBillingSubscriptionChangeDatetime", date)
              }
              dateFormat="yyyy-MM-dd"
              id="account-billing-subscription-change-datetime"
              name="accountBillingSubscriptionChangeDatetime"
            />

            <button type="submit">{buttonText}</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const FeaturesForm = ({ state, authContext, accountUuid }) => {
  let buttonText = "Submit";

  const handleSubmit = async (values: any) => {
    // handle form submission here
    const responseStatus = await updateAccountFeatures(
      authContext.state.jwt,
      accountUuid,
      {
        accountTrackingParams: values.accountTrackingParams,
        accountFeatureFlagLinkScanner: !!values.accountFeatureFlagLinkScanner,
        accountFeatureFlagBrokenLinks: !!values.accountFeatureFlagBrokenLinks,
        accountFeatureFlagRevshare: !!values.accountFeatureFlagRevshare,
        accountLinkScannerLimit: parseInt(values.accountLinkScannerLimit),
        accountLinkScannerHours: parseInt(values.accountLinkScannerHours),
        accountLinkScannerDiscover: !!values.accountLinkScannerDiscover,
      },
    );
    if (responseStatus) {
      buttonText = "Saved";
    } else {
      buttonText = "Error";
    }
  };

  return (
    <div className="flex-1">
      <h2>Account features</h2>
      <Formik
        initialValues={{
          accountTrackingParams: state.account.accountTrackingParams || "",
          accountFeatureFlagLinkScanner:
            !!state.account.accountFeatureFlagLinkScanner || false,
          accountFeatureFlagBrokenLinks:
            !!state.account.accountFeatureFlagBrokenLinks || false,
          accountFeatureFlagRevshare:
            !!state.account.accountFeatureFlagRevshare || false,
          accountLinkScannerLimit: state.account.accountLinkScannerLimit || 500,
          accountLinkScannerHours: state.account.accountLinkScannerHours || 24,
          accountLinkScannerDiscover:
            !!state.account.accountLinkScannerDiscover || false,
        }}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form className="form">
            <label htmlFor="account-tracking-params">
              Inbound custom params
            </label>
            <Field
              type="text"
              id="account-tracking-params"
              name="accountTrackingParams"
              placeholder="tag1=value1&tag2=value2"
            />

            <label htmlFor="account-feature-flag-link-scanner">
              Enable Link Scanner
            </label>
            <Field
              type="checkbox"
              id="account-feature-flag-link-scanner"
              name="accountFeatureFlagLinkScanner"
              checked={values.accountFeatureFlagLinkScanner}
              onChange={() =>
                setFieldValue(
                  "accountFeatureFlagLinkScanner",
                  !values.accountFeatureFlagLinkScanner,
                )
              }
            />

            <label htmlFor="account-link-scanner-limit">
              Link scanner - scan count limit
            </label>
            <Field
              type="text"
              id="account-link-scanner-limit"
              name="accountLinkScannerLimit"
            />

            <label htmlFor="account-link-scanner-hours">
              Link scanner - hour limit
            </label>
            <Field
              type="text"
              id="account-link-scanner-hours"
              name="accountLinkScannerHours"
            />

            <label htmlFor="account-link-scanner-discover">
              Link scanner - scan discover clicks
            </label>
            <Field
              type="checkbox"
              id="account-link-scanner-discover"
              name="accountLinkScannerDiscover"
              checked={values.accountLinkScannerDiscover}
              onChange={() =>
                setFieldValue(
                  "accountLinkScannerDiscover",
                  !values.accountLinkScannerDiscover,
                )
              }
            />

            <label htmlFor="account-feature-flag-broken-links">
              Allow broken links page access
            </label>
            <Field
              type="checkbox"
              id="account-feature-flag-broken-links"
              name="accountFeatureFlagBrokenLinks"
              checked={values.accountFeatureFlagBrokenLinks}
              onChange={() =>
                setFieldValue(
                  "accountFeatureFlagBrokenLinks",
                  !values.accountFeatureFlagBrokenLinks,
                )
              }
            />

            <label htmlFor="account-feature-flag-revshare">
              Activate RevShare feature for this account
            </label>
            <Field
              type="checkbox"
              id="account-feature-flag-revshare"
              name="accountFeatureFlagRevshare"
              checked={values.accountFeatureFlagRevshare}
              onChange={() =>
                setFieldValue(
                  "accountFeatureFlagRevshare",
                  !values.accountFeatureFlagRevshare,
                )
              }
            />

            <button type="submit">{buttonText}</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const NetworksForm = ({ state, authContext, accountUuid }) => {
  const [importingUuids, setImportingUuids] = useState([]);
  const [errorUuids, setErrorUuids] = useState([]);

  const forceReimportPrograms = useCallback(
    async (connectionUuid) => {
      setImportingUuids([...importingUuids, connectionUuid]);
      const responseStatus = await reimportAccountPrograms(
        authContext.state.jwt,
        accountUuid,
        connectionUuid,
      );
      if (!responseStatus) {
        setErrorUuids([...errorUuids, connectionUuid]);
      }
    },
    [
      authContext,
      accountUuid,
      importingUuids,
      setImportingUuids,
      errorUuids,
      setErrorUuids,
    ],
  );

  if (!state.account?.accountConnections?.length) {
    return null;
  }

  return (
    <div className="flex-1">
      <h2>Connected networks</h2>
      <NetworkButton
        importing={importingUuids.includes("")}
        error={errorUuids.includes("")}
        onClick={forceReimportPrograms}
      />
      {state.account.accountConnections.map((connection) => (
        <NetworkButton
          key={connection.connectionUuid}
          importing={importingUuids.includes(connection.connectionUuid)}
          error={errorUuids.includes(connection.connectionUuid)}
          onClick={forceReimportPrograms}
          connection={connection}
        />
      ))}
    </div>
  );
};

const NetworkButton = ({ importing, error, connection, onClick }) => {
  if (error) {
    return (
      <div
        style={{
          marginTop: "1rem",
          padding: "0.25rem 0rem 0.75rem",
          height: 15.5,
        }}
      >
        Something went wrong
      </div>
    );
  }

  if (importing) {
    return (
      <div
        style={{
          marginTop: "1rem",
          padding: "0.25rem 0rem 0.75rem",
          height: 15.5,
        }}
      >
        Re-import started
      </div>
    );
  }

  if (connection) {
    const label = [
      "Re-import",
      connection.connectionNetworkName,
      connection.connectionName,
      "-",
      connection.connectionApiPartnerId,
      `(${moment(connection.connectionCreatedDatetime).format("DD MMM 'YY")})`,
    ]
      .filter((v) => v)
      .join(" ");

    return (
      <div>
        <button
          type="button"
          onClick={() => onClick(connection.connectionUuid)}
        >
          {label}
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <button type="button" onClick={() => onClick("")}>
          Re-import all connections
        </button>
      </div>
    );
  }
};

export default Accounts;
