import axios from "axios";
import { IStatus } from "./status.interface";
import { host } from "../shared/api";

const getStatus = async function (jwt: string): Promise<IStatus[]> {
  try {
    const response = await axios.get(`${host}/odin/status/v1/status`, {
      headers: { Authorization: `Bearer ${jwt}` },
    });
    return response.data?.data || [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export { getStatus };
