import React, { useReducer, createContext, Dispatch } from "react";
import { IStatus } from "./status.interface";

type Action = { type: "SET_STATUS"; status: IStatus };

const initialState: { status: IStatus[] } = {
  opportunityBlacklistDomains: [],
};

const reducer = (state: typeof initialState = initialState, action: Action) => {
  switch (action.type) {
    case "SET_STATUS":
      state.status = action.status;
      const newState = { ...state };
      return newState;
    default:
      return state;
  }
};

const StatusContext = createContext<{
  state: typeof initialState;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

const StatusProvider: React.FC = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <StatusContext.Provider value={value}>
      {props.children}
    </StatusContext.Provider>
  );
};

export { StatusProvider, StatusContext, initialState };
